import React, { useMemo } from "react";

type AnimatedCircleProps = {
  cx: string;
  cy: string;
  r: string;
  duration: number;
  className: string;
};

const AnimatedCircle: React.FC<AnimatedCircleProps> = ({ cx, cy, r, duration, className }) => {
  const animationDuration = useMemo(() => {
    return Math.floor(Math.random() * duration * 1000 + 3000);
  }, [duration]);
  return (
    <circle
      fill="#cdd2ff"
      className={className}
      cx={cx}
      cy={cy}
      r={r}
      style={{
        transition: "opacity 600ms",
        animationName: "colorframes",
        animationDuration: `${animationDuration}ms`,
        animationIterationCount: "infinite",
        animationPlayState: "running",
      }}
    />
  );
};

export default AnimatedCircle;

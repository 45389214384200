import type { FC } from "react";
import AnimatedCircle from "~/components/home/Map/AnimatedCircle.tsx";

const AnimatedMap: FC = () => {
  return (
    <svg viewBox="0 0 845.2 458">
      <AnimatedCircle duration={1} className="st0" cx="731.2" cy="354.5" r="1.9" />
      <AnimatedCircle duration={20} className="st0" cx="731.2" cy="361.3" r="1.9" />
      <AnimatedCircle duration={7} className="st0" cx="731.2" cy="368" r="1.9" />
      <AnimatedCircle duration={39} className="st0" cx="731.2" cy="374.8" r="1.9" />
      <AnimatedCircle duration={16} className="st0" cx="731.2" cy="381.6" r="1.9" />

      <AnimatedCircle duration={4} className="st0" cx="724.4" cy="354.5" r="1.9" />
      <AnimatedCircle duration={10} className="st0" cx="724.4" cy="361.3" r="1.9" />
      <AnimatedCircle duration={20} className="st0" cx="724.4" cy="368" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="724.4" cy="374.8" r="1.9" />

      <AnimatedCircle duration={9} className="st0" cx="717.6" cy="178.1" r="1.9" />
      <AnimatedCircle duration={29} className="st0" cx="717.6" cy="184.9" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="717.6" cy="191.7" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="717.6" cy="198.5" r="1.9" />

      <AnimatedCircle duration={3} className="st0" cx="717.6" cy="340.9" r="1.9" />
      <AnimatedCircle duration={29} className="st0" cx="717.6" cy="347.7" r="1.9" />
      <AnimatedCircle duration={11} className="st0" cx="717.6" cy="354.5" r="1.9" />

      <AnimatedCircle duration={9} className="st0" cx="710.8" cy="198.5" r="1.9" />
      <AnimatedCircle duration={29} className="st0" cx="710.8" cy="205.3" r="1.9" />

      <AnimatedCircle duration={29} className="st0" cx="588.7" cy="232.4" r="1.9" />
      <AnimatedCircle duration={7} className="st0" cx="588.7" cy="239.2" r="1.9" />

      <AnimatedCircle duration={19} className="st0" cx="582" cy="232.4" r="1.9" />
      <AnimatedCircle duration={14} className="st0" cx="582" cy="239.2" r="1.9" />

      <AnimatedCircle duration={12} className="st0" cx="582" cy="259.5" r="1.9" />

      <AnimatedCircle duration={10} className="st0" cx="575.2" cy="232.4" r="1.9" />
      <AnimatedCircle duration={4} className="st0" cx="575.2" cy="239.2" r="1.9" />

      <AnimatedCircle duration={9} className="st0" cx="575.2" cy="252.7" r="1.9" />
      <AnimatedCircle duration={2} className="st0" cx="575.2" cy="259.5" r="1.9" />

      <AnimatedCircle duration={2} className="st0" cx="568.4" cy="232.4" r="1.9" />
      <AnimatedCircle duration={10} className="st0" cx="568.4" cy="239.2" r="1.9" />

      <AnimatedCircle duration={12} className="st0" cx="568.4" cy="252.7" r="1.9" />

      <AnimatedCircle duration={16} className="st0" cx="561.6" cy="218.8" r="1.9" />
      <AnimatedCircle duration={6} className="st0" cx="561.6" cy="225.6" r="1.9" />

      <AnimatedCircle duration={31} className="st0" cx="561.6" cy="239.2" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="554.8" cy="212" r="1.9" />
      <AnimatedCircle duration={8} className="st0" cx="554.8" cy="218.8" r="1.9" />
      <AnimatedCircle duration={16} className="st0" cx="554.8" cy="225.6" r="1.9" />
      <AnimatedCircle duration={2} className="st0" cx="554.8" cy="232.4" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="554.8" cy="239.2" r="1.9" />

      <AnimatedCircle duration={10} className="st0" cx="514.1" cy="232.4" r="1.9" />

      <AnimatedCircle duration={21} className="st0" cx="514.1" cy="252.7" r="1.9" />

      <AnimatedCircle duration={3} className="st0" cx="507.4" cy="232.4" r="1.9" />

      <AnimatedCircle duration={8} className="st0" cx="500.6" cy="252.7" r="1.9" />

      <AnimatedCircle duration={6} className="st0" cx="493.8" cy="212" r="1.9" />
      <AnimatedCircle duration={16} className="st0" cx="493.8" cy="218.8" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="493.8" cy="225.6" r="1.9" />
      <AnimatedCircle duration={12} className="st0" cx="493.8" cy="232.4" r="1.9" />
      <AnimatedCircle duration={2} className="st0" cx="493.8" cy="239.2" r="1.9" />
      <AnimatedCircle duration={21} className="st0" cx="493.8" cy="246" r="1.9" />
      <AnimatedCircle duration={26} className="st0" cx="493.8" cy="259.5" r="1.9" />

      <AnimatedCircle duration={16} className="st0" cx="487" cy="198.5" r="1.9" />
      <AnimatedCircle duration={2} className="st0" cx="487" cy="205.3" r="1.9" />
      <AnimatedCircle duration={10} className="st0" cx="487" cy="212" r="1.9" />
      <AnimatedCircle duration={4} className="st0" cx="487" cy="218.8" r="1.9" />
      <AnimatedCircle duration={11} className="st0" cx="487" cy="225.6" r="1.9" />

      <AnimatedCircle duration={9} className="st0" cx="453.1" cy="171.3" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="453.1" cy="178.1" r="1.9" />
      <AnimatedCircle duration={29} className="st0" cx="453.1" cy="184.9" r="1.9" />
      <AnimatedCircle duration={3} className="st0" cx="453.1" cy="191.7" r="1.9" />
      <AnimatedCircle duration={11} className="st0" cx="453.1" cy="198.5" r="1.9" />
      <AnimatedCircle duration={20} className="st0" cx="453.1" cy="212" r="1.9" />
      <AnimatedCircle duration={1} className="st0" cx="453.1" cy="218.8" r="1.9" />

      <AnimatedCircle duration={9} className="st0" cx="446.3" cy="157.8" r="1.9" />
      <AnimatedCircle duration={1} className="st0" cx="446.3" cy="164.6" r="1.9" />
      <AnimatedCircle duration={29} className="st0" cx="446.3" cy="171.3" r="1.9" />
      <AnimatedCircle duration={3} className="st0" cx="446.3" cy="178.1" r="1.9" />
      <AnimatedCircle duration={17} className="st0" cx="446.3" cy="184.9" r="1.9" />
      <AnimatedCircle duration={20} className="st0" cx="446.3" cy="191.7" r="1.9" />

      <AnimatedCircle duration={11} className="st0" cx="446.3" cy="218.8" r="1.9" />

      <AnimatedCircle duration={5} className="st0" cx="439.5" cy="218.8" r="1.9" />

      <AnimatedCircle duration={5} className="st0" cx="432.8" cy="123.9" r="1.9" />

      <AnimatedCircle duration={15} className="st0" cx="432.8" cy="164.6" r="1.9" />
      <AnimatedCircle duration={8} className="st0" cx="432.8" cy="171.3" r="1.9" />
      <AnimatedCircle duration={15} className="st0" cx="432.8" cy="178.1" r="1.9" />
      <AnimatedCircle duration={7} className="st0" cx="432.8" cy="191.7" r="1.9" />

      <AnimatedCircle duration={3} className="st0" cx="432.8" cy="225.6" r="1.9" />

      <AnimatedCircle duration={9} className="st0" cx="426" cy="117.1" r="1.9" />
      <AnimatedCircle duration={11} className="st0" cx="426" cy="123.9" r="1.9" />
      <AnimatedCircle duration={3} className="st0" cx="426" cy="130.7" r="1.9" />
      <AnimatedCircle duration={39} className="st0" cx="426" cy="137.4" r="1.9" />
      <AnimatedCircle duration={6} className="st0" cx="426" cy="151" r="1.9" />

      <AnimatedCircle duration={11} className="st0" cx="426" cy="225.6" r="1.9" />

      <AnimatedCircle duration={11} className="st0" cx="419.2" cy="164.6" r="1.9" />
      <AnimatedCircle duration={1} className="st0" cx="419.2" cy="171.3" r="1.9" />
      <AnimatedCircle className="st0" cx="419.2" cy="178.1" r="1.9" duration={3} />
      <AnimatedCircle duration={5} className="st0" cx="419.2" cy="184.9" r="1.9" />

      <AnimatedCircle duration={6} className="st0" cx="412.4" cy="164.6" r="1.9" />
      <AnimatedCircle duration={8} className="st0" cx="412.4" cy="171.3" r="1.9" />

      <AnimatedCircle duration={26} className="st0" cx="405.6" cy="164.6" r="1.9" />

      <AnimatedCircle duration={6} className="st0" cx="405.6" cy="205.3" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="405.6" cy="225.6" r="1.9" />

      <AnimatedCircle duration={15} className="st0" cx="398.8" cy="178.1" r="1.9" />

      <AnimatedCircle duration={9} className="st0" cx="297.1" cy="300.2" r="1.9" />
      <AnimatedCircle duration={29} className="st0" cx="297.1" cy="307" r="1.9" />
      <AnimatedCircle duration={1} className="st0" cx="297.1" cy="313.8" r="1.9" />
      <AnimatedCircle duration={13} className="st0" cx="297.1" cy="320.6" r="1.9" />
      <AnimatedCircle duration={39} className="st0" cx="297.1" cy="327.3" r="1.9" />
      <AnimatedCircle duration={6} className="st0" cx="297.1" cy="334.1" r="1.9" />
      <AnimatedCircle duration={11} className="st0" cx="297.1" cy="340.9" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="290.3" cy="313.8" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="290.3" cy="320.6" r="1.9" />
      <AnimatedCircle duration={15} className="st0" cx="290.3" cy="327.3" r="1.9" />
      <AnimatedCircle duration={29} className="st0" cx="290.3" cy="334.1" r="1.9" />
      <AnimatedCircle duration={33} className="st0" cx="290.3" cy="340.9" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="290.3" cy="347.7" r="1.9" />

      <AnimatedCircle duration={19} className="st0" cx="283.5" cy="300.2" r="1.9" />
      <AnimatedCircle duration={1} className="st0" cx="283.5" cy="307" r="1.9" />
      <AnimatedCircle duration={7} className="st0" cx="283.5" cy="313.8" r="1.9" />
      <AnimatedCircle duration={10} className="st0" cx="283.5" cy="320.6" r="1.9" />
      <AnimatedCircle duration={20} className="st0" cx="283.5" cy="327.3" r="1.9" />
      <AnimatedCircle duration={5} className="st0" cx="283.5" cy="334.1" r="1.9" />
      <AnimatedCircle duration={35} className="st0" cx="283.5" cy="340.9" r="1.9" />
      <AnimatedCircle duration={4} className="st0" cx="283.5" cy="347.7" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="276.8" cy="307" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="270" cy="293.4" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="270" cy="300.2" r="1.9" />
      <AnimatedCircle duration={42} className="st0" cx="270" cy="307" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="270" cy="313.8" r="1.9" />
      <AnimatedCircle duration={20} className="st0" cx="270" cy="320.6" r="1.9" />
      <AnimatedCircle duration={3} className="st0" cx="270" cy="327.3" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="270" cy="334.1" r="1.9" />

      <AnimatedCircle duration={19} className="st0" cx="263.2" cy="320.6" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="263.2" cy="327.3" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="195.4" cy="171.3" r="1.9" />
      <AnimatedCircle duration={16} className="st0" cx="195.4" cy="178.1" r="1.9" />
      <AnimatedCircle duration={5} className="st0" cx="195.4" cy="184.9" r="1.9" />
      <AnimatedCircle duration={21} className="st0" cx="195.4" cy="191.7" r="1.9" />
      <AnimatedCircle duration={1} className="st0" cx="195.4" cy="198.5" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="195.4" cy="205.3" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="195.4" cy="212" r="1.9" />

      <AnimatedCircle duration={19} className="st0" cx="188.6" cy="191.7" r="1.9" />
      <AnimatedCircle duration={1} className="st0" cx="188.6" cy="198.5" r="1.9" />
      <AnimatedCircle duration={39} className="st0" cx="188.6" cy="205.3" r="1.9" />
      <AnimatedCircle duration={49} className="st1" cx="188.6" cy="212" r="1.9" />

      <AnimatedCircle duration={19} className="st0" cx="175" cy="191.7" r="1.9" />
      <AnimatedCircle duration={1} className="st0" cx="175" cy="198.5" r="1.9" />
      <AnimatedCircle duration={39} className="st0" cx="175" cy="205.3" r="1.9" />
      <AnimatedCircle duration={6} className="st0" cx="175" cy="212" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="161.5" cy="157.8" r="1.9" />
      <AnimatedCircle duration={11} className="st0" cx="161.5" cy="164.6" r="1.9" />
      <AnimatedCircle duration={7} className="st0" cx="161.5" cy="171.3" r="1.9" />
      <AnimatedCircle duration={39} className="st0" cx="161.5" cy="178.1" r="1.9" />
      <AnimatedCircle duration={16} className="st0" cx="161.5" cy="184.9" r="1.9" />
      <AnimatedCircle duration={5} className="st0" cx="161.5" cy="191.7" r="1.9" />
      <AnimatedCircle duration={22} className="st0" cx="161.5" cy="198.5" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="161.5" cy="205.3" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="161.5" cy="212" r="1.9" />
      <AnimatedCircle duration={5} className="st0" cx="161.5" cy="218.8" r="1.9" />
      <AnimatedCircle duration={20} className="st0" cx="161.5" cy="225.6" r="1.9" />
      <AnimatedCircle duration={7} className="st0" cx="161.5" cy="232.4" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="161.5" cy="239.2" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="154.7" cy="178.1" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="154.7" cy="184.9" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="154.7" cy="191.7" r="1.9" />
      <AnimatedCircle duration={20} className="st0" cx="154.7" cy="198.5" r="1.9" />
      <AnimatedCircle duration={5} className="st0" cx="154.7" cy="205.3" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="154.7" cy="212" r="1.9" />
      <AnimatedCircle duration={16} className="st0" cx="154.7" cy="218.8" r="1.9" />
      <AnimatedCircle duration={24} className="st0" cx="154.7" cy="225.6" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="147.9" cy="164.6" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="147.9" cy="171.3" r="1.9" />
      <AnimatedCircle duration={16} className="st0" cx="147.9" cy="178.1" r="1.9" />
      <AnimatedCircle duration={10} className="st1" cx="147.9" cy="184.9" r="1.9" />
      <AnimatedCircle duration={9} className="st0" cx="147.9" cy="191.7" r="1.9" />
      <AnimatedCircle duration={21} className="st0" cx="147.9" cy="198.5" r="1.9" />
      <AnimatedCircle duration={4} className="st0" cx="147.9" cy="205.3" r="1.9" />
      <AnimatedCircle duration={6} className="st0" cx="147.9" cy="212" r="1.9" />
      <AnimatedCircle duration={21} className="st0" cx="147.9" cy="218.8" r="1.9" />
      <AnimatedCircle duration={19} className="st0" cx="147.9" cy="225.6" r="1.9" />

      <AnimatedCircle duration={6} className="st0" cx="141.1" cy="198.5" r="1.9" />

      <AnimatedCircle duration={6} className="st0" cx="134.3" cy="83.2" r="1.9" />

      <AnimatedCircle duration={16} className="st0" cx="134.3" cy="191.7" r="1.9" />

      <AnimatedCircle duration={6} className="st0" cx="107.2" cy="117.1" r="1.9" />
      <AnimatedCircle duration={26} className="st0" cx="107.2" cy="123.9" r="1.9" />
      <AnimatedCircle duration={10} className="st0" cx="107.2" cy="130.7" r="1.9" />

      <AnimatedCircle duration={6} className="st0" cx="107.2" cy="157.8" r="1.9" />
      <AnimatedCircle duration={2} className="st0" cx="107.2" cy="164.6" r="1.9" />
      <AnimatedCircle duration={11} className="st0" cx="100.4" cy="96.7" r="1.9" />
      <AnimatedCircle duration={4} className="st0" cx="100.4" cy="103.5" r="1.9" />
      <AnimatedCircle duration={14} className="st0" cx="100.4" cy="110.3" r="1.9" />
      <AnimatedCircle duration={3} className="st0" cx="100.4" cy="117.1" r="1.9" />
      <AnimatedCircle duration={31} className="st0" cx="100.4" cy="123.9" r="1.9" />

      <AnimatedCircle duration={5} className="st0" cx="100.4" cy="137.4" r="1.9" />

      <AnimatedCircle duration={11} className="st0" cx="93.6" cy="130.7" r="1.9" />

      <AnimatedCircle duration={3} className="st0" cx="39.4" cy="103.5" r="1.9" />
      <AnimatedCircle duration={13} className="st0" cx="39.4" cy="110.3" r="1.9" />
      <AnimatedCircle duration={12} className="st0" cx="39.4" cy="117.1" r="1.9" />

      <AnimatedCircle duration={7} className="st0" cx="39.4" cy="137.4" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="32.6" cy="144.2" r="1.9" />
      <AnimatedCircle duration={1} className="st0" cx="32.6" cy="151" r="1.9" />
      <AnimatedCircle duration={12} className="st0" cx="25.8" cy="103.5" r="1.9" />
      <AnimatedCircle duration={8} className="st0" cx="25.8" cy="110.3" r="1.9" />
      <AnimatedCircle duration={5} className="st0" cx="25.8" cy="117.1" r="1.9" />

      <AnimatedCircle duration={1} className="st0" cx="19" cy="157.8" r="1.9" />
    </svg>
  );
};

export default AnimatedMap;
